<template>
  <div>
    <h2 class="content-header">
      <span v-if="create">{{ $tfo(['common.create', 'common.group,,1']) }}</span>
      <span v-else>{{ $tfo(['common.edit', 'common.group,, 1']) }}</span>
    </h2>
    <el-form
      :size="$vars.sizes.form"
      :label-position="$vars.forms.label_position"
      :labelWidth="$vars.forms.label_width"
      ref="form"
      :name="$options.name"
      :model="item"
      :rules="rules"
    >
      <el-form-item :label="$tf('common.id')" v-if="item.id">
        <span name="id">{{ item.id }}</span>
      </el-form-item>
      <el-form-item :label="$tf('common.name')" prop="name">
        <el-input name="name" v-model="item.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          name="save-btn"
          type="primary"
          @click="saveHandler"
          :disabled="create ? $hasNoPermission('auth.add_group') : $hasNoPermission('auth.change_group')"
          >{{ $tf('common.save') }}</el-button
        >
        <el-button name="delete-btn" type="danger" :disabled="$hasNoPermission('auth.delete_group')" @click="deleteHandler" v-if="!create">{{
          $tf('common.delete')
        }}</el-button>
        <el-button name="back-btn" type="info" :plain="true" @click="cancelHandler">{{ $tf('common.back') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import * as validator from '@/apps/common/validator';

let baseRules = {
  name: [{ required: true, message: 'error.required.field', trigger: 'change' }],
  deduplicateDelay: [
    {
      required: true,
      trigger: 'change',
      tmessage: 'error.field.uint_max_min',
      validator: validator.getNumberValidator({ required: true, unsigned: true, min: 0, max: 10000 })
    }
  ]
};

export default {
  name: 'group-form',
  data: function () {
    return {
      rules: this.$applyRuleMessages(baseRules),
      item: {},
      labels: []
    };
  },
  watch: {
    'item.labels': function (v) {
      this.labels = Object.keys(v || {});
    }
  },
  computed: {
    labelItems() {
      let labelsObject = (this.$store.state.groups.items || []).reduce((m, v) => Object.assign(m, v.labels), {});
      return Object.keys(labelsObject);
    },
    create() {
      return !this.$route.params.id;
    },
    state() {
      return this.$store.state.groups;
    }
  },
  created() {
    let id = this.$route.params.id;
    this.item = _.cloneDeep(this.state.item.empty);

    if (id) {
      this.$store
        .dispatch(this.state.Action.Get, { id: decodeURIComponent(id) })
        .then((v) => {
          this.item = v;
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        });
    }

    this.$store.dispatch(this.$store.state.groups.Action.Get);
  },
  methods: {
    afterCreate() {
      this.$router.push({ path: '/groups/' + this.item.id + '/' });
    },
    previewFileHandler() {},
    deleteFileHandler() {},
    saveHandler(e) {
      this.$refs.form.validate(this.validateHandler);
    },
    validateHandler(valid) {
      if (!valid) return;
      this.item.labels = (this.labels || []).reduce((m, v) => {
        m[v] = 'true';
        return m;
      }, {});
      let action = this.create ? this.state.Action.Create : this.state.Action.Update;
      this.$store
        .dispatch(action, this.item)
        .then((v) => {
          this.item.id = v.id;
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$store.dispatch(this.$store.state.groups.Action.Get).then((v) => {
            this.$router.push({ path: `/groups/${v.id}/` });
          });
        })
        .catch((e) => {
          this.$notify({ message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    deleteHandler(e) {
      this.$store
        .dispatch(this.state.Action.Delete, this.item)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$router.push({ path: '/groups/' });
        })
        .catch((e) => {
          this.$notify({ message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    cancelHandler(e) {
      this.$router.backTo({ path: '/groups/' });
    }
  }
};
</script>
